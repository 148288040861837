<template>
	<v-container>
		<v-row>
			<v-col cols="12" sm="6" md="4" lg="4" v-for="content in Contents" :key="content.ContentId">

				<v-card
					:to="'./Blog/' + content.FriendlyUrl">
					<v-container>
						<v-row
							>
							<v-col cols="5"><v-img v-if="content.ImageFilePath"
class="rounded"
:src="content.ImageFilePath[400]"></v-img>
							</v-col>
							<v-col cols="7">
								<h4 class="poppins-medium grey--text text--darken-2"  style="font-size: 1rem;">
								{{ content.Title }}</h4>
							</v-col>
						</v-row>
					</v-container>

				</v-card>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>
// src\views\Admin\Content\components\StaticContent\Viewer.vue
// import StaticContentViewer from "@/views/Admin/Content/components/StaticContent/Viewer";
import CrudClient from "@/services/CrudClient/";

export default {
	name: "StaticContentPage",
	components: { },
	data: () => ({
		Contents: []
	}),
	props: {
		ContentIds: {
			type: Array,
			require: true,
		},

	},
	async mounted() {
		this.$log.info("StaticContent Grid View Mounted");
		const StaticContentService = new CrudClient("StaticContent");

		for (let i = 0; i < this.ContentIds.length; i++) {
			this.Contents.push(await StaticContentService.GetSelectedFields(this.ContentIds[i], "ContentId, Title, FriendlyUrl, ImageFilePath"));
		}
	},
};
</script>
