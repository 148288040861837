<template>
	<div>
		<v-container>
			<v-row>
				<v-col>
					<div
						class="poppins-semibold nyGreenAlt--text text--darken-2"
						style="font-size: 0.8rem"
					>
						Ciao {{ UserProfile.Name }}!
					</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6" order="1">
					<h3 class="poppins-semibold mb-4" style="font-size: 1.2rem">
						Prossimi appuntamenti
					</h3>

					<BookingCard
						v-for="booking in UpcomingBookings"
						:key="booking.BookingId"
						:booking="booking"
						@bookingModified="LoadNextBookings()"
					></BookingCard>

					<v-btn
						block
						color="nyGreen darken-3"
						dark
						class="text-capitalize"
						to="/AreaNutritionists/Bookings"
					>
						<v-icon left>fas fa-list</v-icon>Tutti
						appuntamenti</v-btn
					>

					<v-divider class="my-5"></v-divider>
				</v-col>
				<v-col cols="12" md="6" order="3" order-md="2" v-if="NotConcludedBookings.length > 0">
					<h3 class="poppins-semibold mb-4" style="font-size: 1.2rem">
						Appuntamenti non ancora completati
					</h3>

					<v-alert
						type="warning"

						class="mb-4"
					>
						Attenzione, ci sono
						{{ NotConcludedBookings.length }} appuntamenti passati
						ancora in sospeso.
						<br />
						Ti invitiamo a verificarli e aggiornare il loro stato
						segnalandoli come annullati o completati, oppure
						correggendo la data.
					</v-alert>

					<BookingCard
						v-for="booking in NotConcludedBookings"
						:key="booking.BookingId"
						:booking="booking"
						@bookingModified="LoadNotConcludedBookings()"
					></BookingCard>
					<v-divider class="my-5"></v-divider>
				</v-col>
				<v-col
					cols="12"
					md="6"
					v-if="CalendarSlotsAvailable"
					order="2"
					order-md="3"
				>
					<h3 class="poppins-semibold mb-4" style="font-size: 1.2rem">
						Le tue disponibilità in calendario
					</h3>

					<v-skeleton-loader
						type="paragraph"
						v-if="LoadingSlots"
					></v-skeleton-loader>
					<v-alert
						type="warning"
						v-if="AvailableIntervals.length < 4"
					>
						Attenzione! Hai pochi slot disponibili nei prossimi
						giorni. Ti consigliamo di aggiungere al più presto nuove
						disponibilità.
					</v-alert>
					<div class="mb-3">
						<div
							v-for="slot in AvailableIntervals"
							:key="slot.IntervalId"
						>
							<div class="">
								<span class="poppins-semibold mr-3">
								{{ slot.IntervalDate }}
									</span>

								<v-chip label color="nyGreenAlt darken-2" dark >
									{{ slot.IntervalStart }} -
									{{ slot.IntervalEnd }}
								</v-chip>
							</div>
						</div>
					</div>

					<div>
						<v-btn
							block
							color="nyGreen darken-3"
							dark
							to="/AreaNutritionists/Calendar"
							class="text-capitalize"
						>
							<v-icon left>fas fa-calendar-alt</v-icon>Vai al
							calendario</v-btn
						>
					</div>
					<v-divider class="my-5"></v-divider>
				</v-col>
			</v-row>
		</v-container>
	</div>
</template>
<script>
import { BookingStatusIdEnum } from "@/components/NutriYou/nutriYouBookingStatusEnum";
import { BookingTypesEnum } from "@/components/NutriYou/nutriYouBookingTypesEnum";
import { mapActions, mapGetters } from "vuex";
import moment from "moment/moment";
import CrudClient from "@/services/CrudClient";
import BookingCard from "@/views/Nutritionists/components/BookingCard.vue";

export default {
	name: "NutritionistHome",
	components: { BookingCard },
	computed: {
		BookingStatusIdEnum() {
			return BookingStatusIdEnum;
		},
		BookingTypesEnum() {
			return BookingTypesEnum;
		},
		...mapGetters(["UserProfile", "TotalNewMessages"])
	},
	data() {
		return {
			CalendarSlotsAvailable: false,
			LoadingSlots: false,
			AvailableIntervals: [],
			UpcomingBookings: [],
			LoadingData: false,

			NotConcludedBookings: [],
			LoadingNotConcludedData: false
		};
	},
	created() {
		this.BookingsService = new CrudClient("CurrentNutritionist/Bookings");
		this.DailyAvailabilitiesService = new CrudClient(
			"CurrentNutritionist/DailyAvailabilities"
		);
		this.NutritionistsService = new CrudClient(
			"CurrentNutritionist/Nutritionist"
		);
	},
	async mounted() {
		await this.LoadNutritionistData();
		if (this.CalendarSlotsAvailable) {
			await this.LoadAvailableIntervals();
		}

		await this.LoadNextBookings();
		await this.LoadNotConcludedBookings();
	},
	methods: {
		...mapActions(["snackSuccess", "snackError"]),

		async LoadNutritionistData() {
			const res = await this.NutritionistsService.GetSelectedFields(
				null,
				"CalendarSlotsAvailable"
			);

			this.CalendarSlotsAvailable = res.CalendarSlotsAvailable;
		},

		async LoadAvailableIntervals() {
			try {
				this.LoadingSlots = true;
				const formattedStartDate = moment()
					.add(1, "days")
					.format("YYYY-MM-DD");
				const formattedEndDate = moment()
					.add(7, "days")
					.format("YYYY-MM-DD");

				const res = await this.DailyAvailabilitiesService.GetPaged({
					skip: 0,
					fields: "*",
					filter: `IntervalDate:between:${formattedStartDate},${formattedEndDate}`,
					orderBy: "IntervalDate:asc"
				});
				const results = res.Data;

				this.AvailableIntervals = results.map(interval => {
					return {
						IntervalDate: moment(interval.IntervalDate).format(
							"DD/MM/YYYY"
						),
						IntervalStart: moment(
							interval.IntervalStart,
							"HH:mm:ss"
						).format("HH:mm"),
						IntervalEnd: moment(
							interval.IntervalEnd,
							"HH:mm:ss"
						).format("HH:mm")
					};
				});
			} catch (error) {
				this.$captureError(error);
				this.snackError({ Text: "Operazione non riuscita" });
			} finally {
				this.LoadingSlots = false;
			}
		},

		async LoadNextBookings() {
			try {
				const todayFormatted = moment().format("YYYY-MM-DD");

				const endDateFormatted = moment()
					.add(3, "days")
					.format("YYYY-MM-DD");

				this.LoadingData = true;
				const res = await this.BookingsService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "",
					filter: `BookingStatusId:notin:2,3;BookingTime:between:${todayFormatted},${endDateFormatted}`,
					orderBy: "BookingTime:asc"
				});
				this.UpcomingBookings = res.Data;
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.LoadingData = false;
			}
		},

		async LoadNotConcludedBookings() {
			try {
				const todayFormatted = moment().format("YYYY-MM-DD");

				this.LoadingNotConcludedData = true;
				const res = await this.BookingsService.GetPaged({
					limit: 0,
					skip: 0,
					fields: "",
					filter: `BookingStatusId:notin:2,3;BookingTime:lt:${todayFormatted}`,
					orderBy: "BookingTime:asc"
				});
				this.NotConcludedBookings = res.Data;
			} catch (error) {
				this.snackError({
					Text: this.$t("common.error.cannotLoadData")
				});
				this.$captureError(error);
			} finally {
				this.LoadingNotConcludedData = false;
			}
		}
	}
};
</script>

<style scoped lang="scss"></style>
