const routes = [{
	path: "/Admin/Dashboard",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./Index.vue")
},
{
	path: "/Admin/Dashboard/MonthlySummary",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./MonthlySummary.vue")
},
{
	path: "/Admin/Dashboard/ConversionTimeline",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./ConversionTimeline.vue")
},
{
	path: "/Admin/Dashboard/Pivot",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./Pivot.vue")
},
{
	path: "/Admin/Dashboard/NutritionistConversionRates",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./NutritionistConversionRates.vue")
},
{
	path: "/Admin/Dashboard/NutritionistPatientTimeline",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./NutritionistPatientTimeline.vue")
},
{
	path: "/Admin/Dashboard/DailyPayments",
	component: () => import(/* webpackChunkName: "Dashboard" */ "./ReportDailyPayments.vue")
}
];

routes.forEach(r => {
	r.meta = {
		requiresAuth: true,
		allowedRoles: ["BusinessAdmin"]
	};
}
);

export default routes;
